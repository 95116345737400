const api = '/order-service/rest/api/external/requestsForQuotation';
export const requestsForQuotationsContext = {
  all: `${api}`,
  byId: `${api}/{id}`,
  status: `${api}/{id}/status`,
  messages: `${api}/{id}/messages`,
  negotiations: `${api}/{id}/negotiations`,
  updateSellerPrice: `${api}/{id}/negotiations/offer`,
  decision: `${api}/{id}/negotiations/decision`,
  addAttachment: `${api}/attachments`,
  changeDecision: `${api}/{id}/negotiations/decision`,
};
