export const ordersClientContext = {
  addItem: '/order-service/rest/api/external/orders/{id}/addItem',
  count: '/order-service/rest/api/external/orders/{id}/changeCount',
  comment: '/order-service/rest/api/external/orders/{id}/comment',
  getByPage: '/order-service/rest/api/external/orders',
  getUnited: '/order-service/rest/api/external/unitedOrders/{id}',
  getUnitedAll: '/order-service/rest/api/external/unitedOrders',
  get: '/order-service/rest/api/external/orders/',
  invoice: '/order-service/rest/api/external/orders/{id}/invoice',
  orderInvoice: '/order-service/rest/api/external/orders/invoice',
  fieldsStructure: '/order-service/api/external/fieldsStructure',
  additionalFields: '/order-service/rest/api/external/orders/{id}/additionalFields',
  performActionActive: '/order-service/rest/api/external/orders/{id}/performAction',
  statistics: '/order-service/rest/api/external/sellers/self/statistics',
  getOperatorOrders: '/web-api/orders',
  metaship: '/logistics-service/api/external/orders/{id}/label',
  deliveryStatus: '/logistics-service/api/external/orders/{id}/status',
  deliveryDocument: '/logistics-service/api/external/orders/{id}/doc',
  delivery: '/logistics-service/api/external/orders/{id}',
  changePeriod: '/order-service/rest/api/external/orders/{id}/period',
  receiveChallan: '/order-service/rest/api/external/orders/{id}/challan',
  changeTotal: '/order-service/rest/api/external/orders/{id}/changeTotal',
  invoiceTemplates: '/order-service/rest/api/external/invoiceTemplates',
  invoiceTemplate: '/order-service/rest/api/external/invoiceTemplates/{id}',
  countBadge: '/order-service/rest/api/external/orders/count',
  getOrderLimits: '/order-service/rest/api/external/sellerOrdersLimits',
  createOrderLimits: '/order-service/rest/api/external/sellerOrdersLimits',
  removeOrderLimits: '/order-service/rest/api/external/sellerOrdersLimits/{id}',
  sla: '/order-service/rest/api/external/slaThresholds',
  slaByID: '/order-service/rest/api/external/slaThresholds/{id}',
  deliveryEdit: '/order-service/rest/api/external/orders/{id}/deliveryAddress',
  cancelledOrdersAll: '/order-service/rest/api/external/orderModifications',
  modificationReasons: '/order-service/rest/api/external/orderModificationsReasons',
  cancelOrder: '/order-service/rest/api/external/orders/{id}/cancel',
  cancelReasons: '/order-service/rest/api/external/cancelReasons',
  sellerManualLockStatus: '/order-service/rest/api/external/sellerOrdersLimits/manualLock',
  getBarcodeImage: '/order-service/rest/api/external/deliveryBarcodes/{id}',
  updateBarcodes: '/order-service/rest/api/external/orders/{id}/deliveryBarcodes',
  generateBarcode: '/order-service/rest/api/external/deliveryBarcodes',
};
